import React, { useState } from "react";
import { Check, X, HelpCircle } from "lucide-react";
import { Card, CardHeader, CardContent } from "./ui/card";

interface Package {
  name: string;
  price: number;
  contract: string;
  tagline: string;
}

interface Feature {
  name: string;
  category: string;
  basic: boolean | string;
  essential: boolean | string;
  standard: boolean | string;
  unlimited: boolean | string;
}

interface FeatureDescription {
  fullTitle: string;
  what: string;
  why: string;
}

interface FeatureRowProps {
  feature: Feature;
  selectedPlan: string | null;
}

interface TooltipProps {
  content: FeatureDescription;
  position: "bottom-right" | "top-right";
}

const packages: Package[] = [
  {
    name: "Basic",
    price: 2000,
    contract: "3 Year Contract",
    tagline: "A starting place to address workplace violence",
  },
  {
    name: "Essential",
    price: 9000,
    contract: "3 Year Contract",
    tagline: "Equipping you to address workplace violence",
  },
  {
    name: "Standard",
    price: 25000,
    contract: "3 Year Contract",
    tagline: "Partnering with you to address workplace violence",
  },
  {
    name: "Unlimited",
    price: 50000,
    contract: "4 Year Contract",
    tagline: "The solution to workplace violence",
  },
];

const features: Feature[] = [
  // Program Development
  {
    category: "Program Development",
    name: "WVPI Needs Assessment",
    basic: false,
    essential: "Template with Instructions",
    standard: true,
    unlimited: true,
  },
  {
    category: "Program Development",
    name: "WVPI Policy",
    basic: "Template with Instructions",
    essential: "Template with Instructions",
    standard: "Prepared for you by CPPS",
    unlimited: "Customized for you by CPPS",
  },
  {
    category: "Program Development",
    name: "WVP Plan",
    basic: "Template with Instructions",
    essential: "Template with Instructions",
    standard: "Prepared for you by CPPS",
    unlimited: "Customized for you by CPPS",
  },
  {
    category: "Program Development",
    name: "Active Assailant Protocol",
    basic: "Template with Instructions",
    essential: "Template with Instructions",
    standard: "Prepared for you by CPPS",
    unlimited: "Customized for you by CPPS",
  },
  {
    category: "Program Development",
    name: "Periodic Inspection Checklist",
    basic: "Template",
    essential: "Template",
    standard: "Template",
    unlimited: "Template",
  },
  {
    category: "Program Development",
    name: "BTAM Program Governance",
    basic: false,
    essential: "Template with Instructions",
    standard: "Prepared for you by CPPS",
    unlimited: "Customized for you by CPPS",
  },
  {
    category: "Program Development",
    name: "TMT Playbook",
    basic: false,
    essential: false,
    standard: false,
    unlimited: "Template",
  },
  {
    category: "Program Development",
    name: "Orientation",
    basic: "Orientation Video",
    essential: "Orientation Video & 1-hour Consult",
    standard: "2-hour Consult",
    unlimited: "5 Consultation Meetings",
  },

  // Training
  {
    category: "Training",
    name: "eLearning Library Access",
    basic: false,
    essential: false,
    standard: false,
    unlimited: true,
  },
  {
    category: "Training",
    name: "WVIC – Awareness Training",
    basic: "Includes 1 Instructor",
    essential: "Includes 2 Instructors",
    standard: "Includes 5 Instructors",
    unlimited: "Includes 10 Instructors",
  },
  {
    category: "Training",
    name: "WVIC – Manager Training",
    basic: "Includes 1 Instructor",
    essential: "Includes 2 Instructors",
    standard: "Includes 5 Instructors",
    unlimited: "Includes 10 Instructors",
  },
  {
    category: "Training",
    name: "WVFT eLearning Course (Employees)",
    basic: false,
    essential: true,
    standard: "Includes Core Customization",
    unlimited: "Includes Core Customization",
  },
  {
    category: "Training",
    name: "WVIT eLearning Course (Managers)",
    basic: false,
    essential: true,
    standard: "Includes Core Customization",
    unlimited: "Includes Core Customization",
  },
  {
    category: "Training",
    name: "Online Threat Management Team Course",
    basic: false,
    essential: "Includes 5 Seats",
    standard: "Includes 8 Seats",
    unlimited: "Includes 30 Seats",
  },
  {
    category: "Training",
    name: "Live Threat Management Team Training",
    basic: false,
    essential: false,
    standard: true,
    unlimited: true,
  },
  {
    category: "Training",
    name: "Executive Training",
    basic: false,
    essential: false,
    standard: "Two hour training",
    unlimited: true,
  },
  {
    category: "Training",
    name: "Quarterly Security Refreshers (Newsletters, Videos, and Posters)",
    basic: false,
    essential: false,
    standard: true,
    unlimited: true,
  },
  {
    category: "Training",
    name: "Crisis Management Tabletop Exercise",
    basic: false,
    essential: false,
    standard: false,
    unlimited: true,
  },
  {
    category: "Training",
    name: "Live Training",
    basic: false,
    essential: false,
    standard: false,
    unlimited: "2 Days",
  },

  // Consultation & Support
  {
    category: "Consultation & Support",
    name: "Annual WVPI Program Review",
    basic: false,
    essential: false,
    standard: false,
    unlimited: true,
  },
  {
    category: "Consultation & Support",
    name: "Real-Time Threat Assessment Support",
    basic: false,
    essential: false,
    standard: "Includes 5 Hours",
    unlimited: "Unlimited Use",
  },

  // Safe Workplace Certification
  {
    category: "Safe Workplace Certification",
    name: "Safe Workplace Certification",
    basic: false,
    essential: false,
    standard: false,
    unlimited: true,
  },
];

const featureDescriptions: { [key: string]: FeatureDescription } = {
  "WVPI Needs Assessment": {
    fullTitle:
      "Workplace Violence Prevention and Intervention Needs Assessment",
    what: "An assessment of your WVPI program to identify areas of improvement and develop a path to consistency with prevailing standards, legislation, and best practices.",
    why: "What are the specific areas your program could be improved? Use the assessment to build a roadmap for your efforts.",
  },
  "WVPI Policy": {
    fullTitle: "Workplace Violence Prevention and Intervention Policy",
    what: "The WVPI Policy is the framework for establishing workplace violence prevention and intervention programs. It includes guidelines for creating a safe workplace, reporting procedures, and steps for intervention.",
    why: "This document ensures your organization has clear, actionable directives to maintain a safe work environment.",
  },
  "WVP Plan": {
    fullTitle: "Workplace Violence Prevention Plan",
    what: "The Workplace Violence Prevention Plan provides a detailed strategy for preventing and responding to workplace violence. It includes risk assessment procedures, preventive measures, and response protocols to ensure comprehensive protection for employees.",
    why: "This document helps you be proactive in managing workplace safety.",
  },
  "Active Assailant Protocol": {
    fullTitle: "Active Assailant Protocol",
    what: "The AA Protocol outlines the procedures to follow in the event of an active assailant incident. It includes emergency response plans, communication strategies, and evacuation procedures to ensure swift and effective action to protect lives.",
    why: "This document is essential for preparing your organization to respond effectively to violent incidents.",
  },
  "Periodic Inspection Checklist": {
    fullTitle: "Periodic Inspection Checklist",
    what: "The Periodic Inspection Checklist provides a repeatable method for identifying potential hazards and ensuring compliance with safety standards. It includes checklists, inspection schedules, and reporting procedures to maintain a safe work environment.",
    why: "This Checklist enables you to continuously monitor and improve your safety measures.",
  },
  "BTAM Program Governance": {
    fullTitle: "Behavioral Threat Assessment and Management Program Governance",
    what: "The BTAM Program Governance outlines the structure and policies for managing a Behavioral Threat Assessment and Management (BTAM) program. It includes roles and responsibilities, protocols for threat assessment, and strategies for intervention and mitigation.",
    why: "This document ensures your organization has a structured and effective approach to managing potential threats.",
  },
  "TMT Playbook": {
    fullTitle: "Threat Management Team Playbook",
    what: "The TMT Playbook is a procedural guide outlining threat assessment and management processes. It outlines the threat assessment process informing a level of potential threat, concepts for developing intervention strategies, and ongoing case management.",
    why: "This document provides repeatable steps your team can use to address threats made to or within your organization.",
  },
  Orientation: {
    fullTitle: "Orientation",
    what: "Administrative instruction designed to help you utilize these resources.",
    why: "This instruction equips you to confidently start building your workplace violence program.",
  },
  "eLearning Library Access": {
    fullTitle: "eLearning Library Access",
    what: "Gain access to CPPS' Media-Based Training Courseware - an extensive online library of security and safety courses. Upload specific courses or the entire library to your learning management system for easy distribution throughout your organization.",
    why: "This library enables you to provide consistent safety and security training across your organization.",
  },
  "WVIC – Awareness Training": {
    fullTitle:
      "Workplace Violence Instructor Certification - Awareness Training",
    what: "This WVIC Awareness online course certifies individuals to teach workplace violence prevention and intervention at the awareness level, equipping them with the skills to educate all employees on identifying warning signs, reporting mechanisms, and basic de-escalation techniques.",
    why: "When eLearning won't work, equip your personnel to deliver an engaging training experience to your employees.",
  },
  "WVIC – Manager Training": {
    fullTitle: "Workplace Violence Instructor Certification - Manager Training",
    what: "The WVIC Manager online course certifies individuals to instruct managers and leaders on advanced workplace violence prevention strategies, including threat awareness, comprehensive de-escalation techniques, and crisis management.",
    why: "When eLearning won't work, equip your personnel to deliver an engaging training experience to your managers and leaders.",
  },
  "WVFT eLearning Course (Employees)": {
    fullTitle:
      "Workplace Violence Fundamental Training (eLearning Course for Employees)",
    what: "A 20-minute interactive eLearning course designed to educate employees on workplace violence prevention and intervention strategies. It covers identifying potential threats, reporting mechanisms, and effective response protocols to ensure a safe and secure work environment.",
    why: "This eLearning course is an efficient and effective way to empower your employees.",
  },
  "WVIT eLearning Course (Managers)": {
    fullTitle:
      "Workplace Violence Intermediate Training (eLearning Course for Managers)",
    what: "A specialized eLearning course for managers focusing on their role in preventing and responding to workplace violence. It covers advanced topics such as reporting to the threat management team, incident response, and how to support affected employees.",
    why: "This eLearning course is an efficient and effective way to empower your managers and leaders.",
  },
  "Online Threat Management Team Course": {
    fullTitle: "Online Threat Management Team eLearning Course",
    what: "This eLearning course equips members of the Threat Management Team with the knowledge and skills needed to effectively assess and manage potential threats. It includes modules on identifying concerning behaviors, risk assessment, and intervention strategies.",
    why: "This eLearning course provides a foundational understanding of behavioral threat assessment and management principles for the individuals on your TMT.",
  },
  "Live Threat Management Team Training": {
    fullTitle: "Live Threat Management Team Training",
    what: "Building on the Online Threat Management Team eLearning Course, this live training session offers hands-on practice and real-time scenario-based exercises. These sessions are led by Certified Threat Managers (CTMs) and designed to enhance your team's ability to assess, manage, and respond to potential threats.",
    why: "The Online TMT eLearning course equips your team members as individuals, this live training equips your TMT to operate as a team.",
  },
  "Executive Training": {
    fullTitle: "Executive Training",
    what: "Specialized training sessions for executive teams focused on leadership in workplace violence prevention. These sessions cover strategic planning, crisis management, and decision-making during high-stress situations.",
    why: "Equipping executives will ensure safety and security remains a priority in your organization.",
  },
  "Quarterly Security Refreshers (Newsletters, Videos, and Posters)": {
    fullTitle: "Quarterly Security Refreshers",
    what: 'This suite of multimedia communication resources ("set") is designed to help you foster a culture of safety and security. One set is released per quarter, each focusing on a singular theme related to workplace violence prevention, active assailant response protocols, and overall security vigilance. Each set includes a written article, narrated video, and digital poster/flyer.',
    why: "Grow a culture of safety and security by utilizing these sets to regularly communicate core principles with your workforce.",
  },
  "Crisis Management Tabletop Exercise": {
    fullTitle: "Crisis Management Tabletop Exercise",
    what: "Simulated tabletop exercises designed to test and improve the organization's crisis management plans. Participants engage in realistic scenarios to practice their response to workplace violence incidents, ensuring readiness and identifying areas for improvement.",
    why: "How well is your team prepared to respond to a crisis? Leverage this exercise to mitigate damage to your organization.",
  },
  "Live Training": {
    fullTitle: "Live Training",
    what: "Two days of expert-led live training sessions tailored to your organization's needs. These interactive workshops cover a range of topics, including workplace violence prevention, de-escalation, active assailant, and high-risk travel safety.",
    why: "Live training allows your team to engage directly with industry experts, practice critical skills in a controlled environment, and receive immediate feedback. This experience enhances retention, builds confidence, and fosters a culture of safety within your organization. By participating in role-playing exercises and real-world scenarios, employees develop situational awareness and decision-making skills to respond to potential threats, creating a safer workplace.",
  },
  "Annual WVPI Program Review": {
    fullTitle:
      "Annual Workplace Violence Prevention and Intervention Program Review",
    what: "A thorough review of the organization's WVPI program conducted annually. This review assesses the effectiveness of the program, identifies areas for improvement, and ensures ongoing compliance with industry standards.",
    why: "Maintain consistency with changing standards and new legislation with this Annual Program Review.",
  },
  "Real-Time Threat Assessment Support": {
    fullTitle: "Real-Time Threat Assessment Support (TMAT)",
    what: "24/7 access to real-time support from threat assessment experts, providing immediate assistance during potential threat situations.",
    why: "This service ensures that organizations have the guidance and expertise needed to effectively manage and mitigate threats as they arise.",
  },
  "Safe Workplace Certification": {
    fullTitle: "Safe Workplace Certification",
    what: "Third-party validation that your organization has implemented a comprehensive WVPI program, demonstrating consistency with prevailing standards and best practices.",
    why: "The only organizational certification to validate workplace violence efforts. Used by organizations to demonstrate consistency with prevailing standards, attract and retain talent, and justification for potential reductions of insurance premiums.",
  },
};

const PackageCard: React.FC<{
  pkg: Package;
  onSelect: (name: string) => void;
  isSelected: boolean;
  anySelected: boolean;
}> = ({ pkg, onSelect, isSelected, anySelected }) => {
  const [isHovered, setIsHovered] = useState(false);

  const getGradientClass = (packageName: string) => {
    switch (packageName) {
      case "Basic":
        return "from-[#8ECEF4] to-[#429DD6]";
      case "Essential":
        return "from-[#67BFF4] to-[#217EBB]";
      case "Standard":
        return "from-[#339EDD] to-[#0C5C98]";
      case "Unlimited":
        return "from-[#197EB9] to-[#023C7A]";
      default:
        return "from-gray-300 to-gray-400";
    }
  };

  return (
    <Card
      className={`flex flex-col h-full transform transition-all duration-300 ${
        isSelected
          ? "scale-105 shadow-xl"
          : anySelected
          ? "opacity-50 hover:opacity-75"
          : "hover:scale-105 hover:shadow-xl"
      }`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <CardHeader
        className={`bg-gradient-to-br ${
          isSelected || !anySelected
            ? getGradientClass(pkg.name)
            : "from-gray-300 to-gray-400"
        } text-white rounded-t-lg`}
      >
        <h2 className="text-xl md:text-2xl font-bold">{pkg.name}</h2>
      </CardHeader>
      <CardContent className="flex-grow flex flex-col justify-between p-4 md:p-6">
        <div>
          <p
            className={`text-3xl md:text-4xl font-extrabold ${
              isSelected ? "text-gray-800" : "text-gray-600"
            }`}
          >
            ${pkg.price.toLocaleString()}
            <span className="text-base md:text-lg font-normal text-gray-500">
              /year
            </span>
          </p>
          <p className="text-xs md:text-sm text-gray-500 mt-2">
            {pkg.contract}
          </p>
          {(isSelected || isHovered) && (
            <p className="mt-4 text-sm md:text-base font-semibold text-gray-700">
              {pkg.tagline}
            </p>
          )}
        </div>
        <button
          className={`mt-4 md:mt-6 font-semibold py-2 px-4 rounded-full transition-colors duration-300 ${
            isSelected
              ? "bg-[#023C7A] text-white"
              : anySelected
              ? "bg-gray-300 text-gray-600 hover:bg-gray-400"
              : "bg-[#197EB9] text-white hover:bg-[#023C7A]"
          }`}
          onClick={() => onSelect(pkg.name)}
        >
          {isSelected ? "Selected" : "See Plan"}
        </button>
      </CardContent>
    </Card>
  );
};

const Tooltip: React.FC<TooltipProps> = ({ content, position }) => {
  const positionClass =
    position === "bottom-right"
      ? "left-full ml-2"
      : "left-full mr-2 bottom-full";

  return (
    <div
      className={`absolute z-10 p-2 text-sm bg-white border border-gray-200 rounded shadow-lg w-96 ${positionClass}`}
    >
      <p className="font-bold mb-2">{content.fullTitle}</p>
      <p className="font-semibold mb-1">What it is:</p>
      <p className="mb-2">{content.what}</p>
      <p className="font-semibold mb-1">Why it matters:</p>
      <p>{content.why}</p>
    </div>
  );
};

const FeatureRow: React.FC<FeatureRowProps> = ({ feature, selectedPlan }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const getTooltipPosition = (category: string) => {
    return category === "Program Development" ? "bottom-right" : "top-right";
  };

  const renderFeatureValue = (value: boolean | string | null) => {
    if (value === null) {
      return <span className="text-gray-400">-</span>;
    } else if (value === true) {
      return <Check className="mx-auto text-green-500" size={24} />;
    } else if (value === false) {
      return <X className="mx-auto text-red-500" size={24} />;
    } else {
      return (
        <div className="flex items-center justify-center">
          <Check className="text-green-500 mr-1" size={20} />
          <span className="text-xs">{value}</span>
        </div>
      );
    }
  };

  return (
    <>
      <tr className="border-b border-gray-200 hover:bg-gray-50 transition-colors duration-150">
        <td className="py-3 px-4 text-left relative">
          <div className="flex items-center">
            <span className="mr-2">{feature.name}</span>
            {featureDescriptions[feature.name] && (
              <div
                className="cursor-help relative"
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
              >
                <HelpCircle size={16} />
                {showTooltip && (
                  <Tooltip
                    content={featureDescriptions[feature.name]}
                    position={getTooltipPosition(feature.category)}
                  />
                )}
              </div>
            )}
          </div>
        </td>
        {selectedPlan ? (
          <td className="py-3 px-4 text-center hidden md:table-cell">
            {renderFeatureValue(
              feature[selectedPlan.toLowerCase() as keyof Feature]
            )}
          </td>
        ) : (
          ["basic", "essential", "standard", "unlimited"].map((pkg) => (
            <td
              key={pkg}
              className="py-3 px-4 text-center hidden md:table-cell"
            >
              {renderFeatureValue(feature[pkg as keyof Feature])}
            </td>
          ))
        )}
      </tr>
      <tr className="md:hidden">
        <td colSpan={2} className="py-2 px-4 bg-gray-50">
          {["Basic", "Essential", "Standard", "Unlimited"].map((pkg) => (
            <div
              key={pkg}
              className={`flex justify-between items-center mb-2 ${
                selectedPlan === pkg ? "bg-blue-100 p-1 rounded" : ""
              }`}
            >
              <span>{pkg}:</span>
              <span className="text-right">
                {renderFeatureValue(
                  feature[pkg.toLowerCase() as keyof Feature]
                )}
              </span>
            </div>
          ))}
        </td>
      </tr>
    </>
  );
};

const FeatureCategory: React.FC<{
  category: string;
  features: Feature[];
  selectedPlan: string | null;
}> = ({ category, features, selectedPlan }) => {
  return (
    <>
      <tr className="bg-gray-100">
        <td
          colSpan={selectedPlan ? 2 : 5}
          className="py-3 px-4 font-semibold text-lg text-gray-700"
        >
          {category}
        </td>
      </tr>
      {features.map((feature, index) => (
        <FeatureRow key={index} feature={feature} selectedPlan={selectedPlan} />
      ))}
    </>
  );
};

const SafeWorkplaceDashboard: React.FC = () => {
  const [selectedPlan, setSelectedPlan] = useState<string | null>(null);

  const handlePlanSelect = (planName: string) => {
    setSelectedPlan((prevPlan: string | null) =>
      prevPlan === planName ? null : planName
    );
  };

  return (
    <div className="p-4 md:p-8 bg-gray-50 min-h-screen">
      <h1 className="text-3xl md:text-4xl font-extrabold text-center mb-8 md:mb-12 text-gray-800">
        Safe Workplace Suite
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-8 mb-8 md:mb-16">
        {packages.map((pkg) => (
          <PackageCard
            key={pkg.name}
            pkg={pkg}
            onSelect={handlePlanSelect}
            isSelected={selectedPlan === pkg.name}
            anySelected={selectedPlan !== null}
          />
        ))}
      </div>
      <div
        className={`bg-white rounded-lg shadow-lg overflow-hidden ${
          selectedPlan ? "max-w-3xl mx-auto" : "w-full"
        }`}
      >
        <table
          className={`w-full ${selectedPlan ? "table-fixed" : "table-auto"}`}
        >
          <thead className="hidden md:table-header-group">
            <tr className="bg-gradient-to-r from-[#197EB9] to-[#023C7A] text-white">
              <th
                className={`text-left py-4 px-4 font-semibold text-lg ${
                  selectedPlan ? "w-2/3" : ""
                }`}
              >
                Features
              </th>
              {selectedPlan ? (
                <th className="py-4 px-4 font-semibold text-lg w-1/3">
                  {selectedPlan}
                </th>
              ) : (
                packages.map((pkg) => (
                  <th
                    key={pkg.name}
                    className="py-4 px-4 font-semibold text-lg"
                  >
                    {pkg.name}
                  </th>
                ))
              )}
            </tr>
          </thead>
          <tbody>
            {[
              "Program Development",
              "Training",
              "Consultation & Support",
              "Safe Workplace Certification",
            ].map((category) => (
              <FeatureCategory
                key={category}
                category={category}
                features={features.filter(
                  (feature) => feature.category === category
                )}
                selectedPlan={selectedPlan}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SafeWorkplaceDashboard;
